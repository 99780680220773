.wrapper{
  width: 90%;
  height: 100vh;
  margin: 0 auto;
  background-color: #658099;
}

header{
  width: 100%;
  height: 150px;
  display: flex;
  background-color: #112433;
}
h2{
  font-size: 1.8rem;
  line-height: 1;
  margin-bottom: 5px;
  font-weight: 600;
  color: #658eaa;
}
h3{
  padding-right: 5rem;
    font-size: 1.3rem;
    font-weight: bold;
}
h4{
 font-size: 16px;
 font-weight: bold;
}
.logoContainer{
  width: 15%;
  padding-top: 20px;
  padding-left: 20px;

}
.headingBox{
  width: 70%;
  color: white;
  text-align: center;
  padding-top: 10px;
}
.mainContainer{
  width: 100%;
  height: 80vh;
  background-color: #112433;

}
.ag-header-row.ag-header-row-column{
  background-color: #304a5f;
  color: white;
}

.ag-header-cell-comp-wrapper{
color: white;
}
.ag-header-cell.-cell-sortable.ag-focus-managed{
  color: white;
}

.ag-body-viewport.ag-row-no-animation.ag-layout-normal{
  font-weight: 600;
}
.modal-title{
  color: #304a5f;
  font-weight: bold;
} 
.csvLink{
  position: absolute;
  right: 50px;
}

.modal-xl {
  --bs-modal-width: 85%;
}
@media (min-width: 1200px)
{
.modal-xl {
    --bs-modal-width: 98%;
}
}
.ag-header-cell-text{
  white-space: normal !important;
} 
.dialog{
  width: 20%;
  height: 20vh;
  margin: 0 auto;
  background-color: #658099;
  align-self: center;
}
.dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.dialog-box {
  background: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  text-align: center;
  font-size:16px;
  font-weight: 500;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;;
}
.ok-button {
  background-color: #304a5f;
  color: #fff;
  padding: 10px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  position: inherit;
}







